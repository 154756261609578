$icon-font-path: "/fonts/";

// Font size
$font-size-base:              18px ;

$font-size-h1:                36px ; // ~36px
$font-size-h2:                32px ; // ~30px
$font-size-h3:                26px ; // ~24px
$font-size-h4:                20px ; // ~18px
$font-size-h5:                $font-size-base ;
$font-size-h6:                ceil(($font-size-base * 0.85)) ; // ~12px

$gray-base:                   #354649 ;
$gray-darker:                 lighten($gray-base, 13.5%) ; // #222
$gray-dark:                   lighten($gray-base, 20%) ;   // #333
$gray:                        lighten($gray-base, 33.5%) ; // #555
$gray-light:                  lighten($gray-base, 46.7%) ; // #777
$gray-lighter:                lighten($gray-base, 93.5%) ; // #eee

//** Global text color on `<body>`.
$text-color:                  $gray-base ;

// Body
$body-bg:                     #f5f8fa ;

// Borders
$laravel-border-color:        darken($body-bg, 10%) ;
$list-group-border:           $laravel-border-color ;
$navbar-default-border:       $laravel-border-color ;
$panel-default-border:        $laravel-border-color ;
$panel-inner-border:          $laravel-border-color ;

$border-radius-base:          0 ;

// Brands
$brand-primary:               #3097D1 ;
$brand-info:                  #8eb4cb ;
$brand-success:               #2ab27b ;
$brand-warning:               #cbb956 ;
$brand-danger:                #bf5329 ;

// Typography
$font-family-sans-serif:      "Rubik", sans-serif ;
$line-height-base:            1.6 ;
$text-color:                  #636b6f ;

// Navbar
$navbar-default-bg:           #fff ;
$navbar-height:               80px ;


  //== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.


$btn-default-color:           white ;
$btn-default-bg:              #b56734 ;
$btn-default-border:          #b56734 ;

$btn-info-color:           #b56734 ;
$btn-info-bg:              white ;
$btn-info-border:          #b56734 ;

$btn-danger-color:            #fff ;
$btn-danger-bg:               $brand-danger ;
$btn-danger-border:           darken($btn-danger-bg, 5%) ;

$btn-border-radius-small: 0;
// Inputs
$input-border:                lighten($text-color, 40%) ;
$input-border-focus:          lighten($brand-primary, 25%) ;
$input-color-placeholder:     lighten($text-color, 30%);

//== Panels
//
//##
$panel-default-text:          #305d7c ;
// $panel-default-border:     transparent ;
$panel-default-heading-bg:    #e0e7e9 ;

$headings-font-weight:        400 ;

//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #e0e7e9 ;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter;

//** Text color for `<input>`s
$input-color:                    $gray-base ;
//** `<input>` border color
$input-border:                   #e0e7e9 ;

//** Border color for inputs on focus
$input-border-focus:             #66afe9 ;

//** Placeholder text color
$input-color-placeholder:        #999 ;

//** `.form-group` margin
$form-group-margin-bottom:       15px ;

$legend-color:                   $gray-dark ;
$legend-border-color:            #e5e5e5 ;

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lighter ;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border ;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed ;