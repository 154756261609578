
/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */

body {
  overflow-x: hidden;
}


/* Toggle Styles */

$ancho-sidebar: 340px;
#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: $ancho-sidebar;
}


/*
Sacado de https://github.com/sass/sass/issues/818
*/

#sidebar-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - #{$navbar-height});
  z-index: 1000;
  position: fixed;
  left: $ancho-sidebar;
  width: 0;
  height: 100%;
  margin-left: -$ancho-sidebar;
  background: $azul;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: $ancho-sidebar;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -$ancho-sidebar;
}


/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: $ancho-sidebar;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 60px;
  color: white;
  cursor: pointer;
}

.sidebar-nav li>ul {
  list-style: none;
  padding-left: 15px;
}

.sidebar-nav li>ul>li>a {
  font-size: 17px;
  line-height: 35px;
}

.sidebar-nav li>ul>li:last-child {
  padding-bottom: 12px;
}

.sidebar-nav>li>ul.hide {
  display: none;
}

.sidebar-nav li>span {
  color: white;
  margin-left: -15px;
}

.sidebar-nav>li.active>span {
  color: $azul;
}

.sidebar-nav li.active {
  font-weight: bold;
  color: $azul;
  background: white;
  border-left: 7px $naranja solid;
}

.sidebar-nav li.active>ul>li>a {
  color: $azul;
  font-weight: normal;
  text-indent: 0;
}

.sidebar-nav li.active>ul>li>a.active {
  color: $naranja;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #fff;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
  color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.only_open {
  display: block;
  visibility: visible;
}

#menu-toggle > p {
  position: fixed;
  top: 100px;
  left: 0;
  padding: 10px;
  background-color: $azul;
  color: white;
  z-index: 10;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #menu-toggle>p {
  left: $ancho-sidebar;
}

@media(min-width:900px) {
  #wrapper{
    padding-left: 0;
  }
  #wrapper.toggled {
    padding-left: $ancho-sidebar;
  }
  /*
  #wrapper.toggled {
    padding-left: 0;
  }
*/
  #sidebar-wrapper{
    width: 0;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: $ancho-sidebar;
  }
  /*
  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }
*/
  #page-content-wrapper,
  #wrapper.toggled #page-content-wrapper {
    padding: 20px;
    position: relative;
  }
  /*
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
 */
  .only_open {
    // display: none;
    // visibility: hidden;
  }
}