
// Fonts
@import url(https://fonts.googleapis.com/css?family=Rubik:400,700);

// Variables
@import "variables";

$azul : #305d7c;
$naranja :#b56734;
$azul-claro: #e0e7e9;

// Bootstrap
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "sidebar";

$choices-primary-color: $naranja;
$choices-border-radius: 0;
$choices-border-radius-item: 0;

@import "../../../node_modules/choices.js/assets/styles/scss/choices.scss";


.flatpickr-current-month .numInputWrapper {
  background: rgba(0,0,0,0.05);
}

.flatpickr-current-month .numInputWrapper span {
  opacity: 1;
}

body {
  padding-top: $navbar-height;
}

body.noOverflow {
  overflow: hidden;
}

div.panel.panel-default > div.panel-heading > h1{
  color: $azul;
  margin-top: 3px;
  margin-bottom: 3px;
}

.naranja {
  color:$naranja;
}

.panel-naranja{
  background-color:$naranja;
  color:white;
  .list-group{
    background-color: white;
  }
}

.azul{
  color:$azul;
}
.panel-azul{
  background-color:$azul;
  color:white;
  .list-group{
    background-color: white;
  }
}

div.panel.panel-azul > div.panel-heading > h3,
div.panel.panel-naranja > div.panel-heading > h3{
  margin-top: 3px;
  margin-bottom: 3px;
}

  .btn-default:hover{
    background-color: $azul;
    border-color: $azul;
  }

  .btn-info:hover{
    color: $azul;
    border-color: $azul;
  }

  .btn{
    min-width: 233px;
    min-height: 37px;
    font-size: 18px;
  }

  a.btn{
    padding-top: 10px;
  }

  input{
    box-shadow: none !important;
  }

  label{
    font-weight: 400 !important;
  }

  body{
    width: 100%;
    height: 100%;
    background: url(/img/fondo.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .navbar-brand{

    padding: 0;

    img {
      padding: 9px;
    }
  }

  /* Fixed scroll*/
  .fixed-scroll{
    height: 200px;
    overflow: auto;
    overflow-x: hidden;
  }

  .fixed-scrollx2{
    height: 487px;
    overflow: auto;
    overflow-x: hidden;
  }

  .container{
    max-width: 100%;
  }

.sweet-alert button.btn-default:hover {
  background-color: $azul;
  border-color: $azul;
}
.sweet-alert button.btn-default {
  color: white;
  background-color: #b56734 !important;
  border-color: #b56734 !important;
}

ul.dropdown-menu{
  min-width:100%;
}

.inline{
  display: inline;
}

.boton-redondo {
  background-color: $azul;
  padding: 7px 7px 6px 8px;
  border-radius:50%;
  color: white;
}
.unoCinco-marTop{
  margin-top: 15px;
}

@media only screen and (min-width: 992px) {
  .izquierda_azul {
    border-left: 2px $azul solid;
    padding-bottom: 20px;
    min-height: 67px;
  }
}

@media only screen and (max-width: 991px) {
  .izquierda_azul {
    border-left: 2px $azul solid;
    padding-bottom: 0px;
    min-height: 0px;
    margin-bottom: 20px;
  }
}

div.padre:last-child > div.izquierda_azul{
  padding-bottom: 3px;
}
@import 'sweetalert';

$imagen-tamaño: 30px;

img.imagen-donde-usuario{
  max-height: $imagen-tamaño;
  width: $imagen-tamaño;
  max-width: $imagen-tamaño !important;
  height: $imagen-tamaño;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
}

img.imagen-usuario{
  max-height: 100px;
  width: auto;
  height: 100px;
}

.navbar-default .container{
  width: 95%;
}

.padre img{
  margin-bottom: 20px;
}

.lapiz-editar{
  float: right;
  font-size: 12px;
  padding-top: 8px;
  color: gray;
  cursor: pointer;
}

.clickeable{
  cursor: pointer;
}
// Para falsear el color de el selector de fechas.

.form-control.flatpickr-input[readonly] {
  background-color: $input-bg;
}

.btn-margin-3px{
  margin: 3px;
}
@import "competencias";

@import "../../../node_modules/toastr/toastr.scss";
// Esconder los componentes al iniciar https://vuejs.org/v2/api/#v-cloak
[v-cloak] {
  display: none;
}

.sigueAquiBtn{
  padding-left: 20px;
  padding-top: 7px;
}

.exp_estudios {
  padding: 25px;
  box-shadow: 0 2px 2px 0 rgba(224,231,233,0.44),
  0 1px 5px 0 rgba(224,231,233,0.42),
  0 3px 1px -2px rgba(224,231,233,3.2);
}
input[type=file]::-webkit-file-upload-button {
  color: white;
  background-color: #b56734;
  border: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #305d7c;
}

@import "estado";