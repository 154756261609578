.competencia-conocimiento {
  color: $azul;
}

.competencia-habilidad {
  color: #1db346;
}

.competencia-aptitud {
  color: orange;
}

.competencia-criterio {
  color: black;
}

ul.lista-competencias {
  & li {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    & > p {
      margin: 0;
      background-color: $azul;
      color: white;
      padding: 7px 5px 2px 13px;
      .glyphicon {
        float: right;
        padding-top: 5px;
        padding-right: 6px;
      }
    }
    & li {
      margin-bottom: -1px;
      & > div {
        padding-left: 8px;
        & label {
          display: block;
          padding-left: 4.4em;
          text-indent: -4.4em;
          &:before {
            text-indent: 0em;
          }
        }
      }
    }
  }
}

.criterio-list .criterio-list-name {
  display:table;
  width: 100%;
  & .nombre{
    display: table-cell;
    padding: 5px;
    height: 100%;
    border-right: solid $list-group-border 1px;
    text-align:center;
    vertical-align:middle;
  }
  & .complejidad {
    display: table-cell;
    padding: 0;
    height: 100%;
    border-right: solid $list-group-border 1px;
    width: 130px;
    text-align:center;
    vertical-align:middle;
  }
  & .deseable {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 150px;
    border-right: solid $list-group-border 1px;
    text-align:center;
    vertical-align:middle;
  }
  & .prioridad {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 100px;
    text-align:center;
    vertical-align:middle;
  }
}
.criterio-list,
.criterio-list-pruebas-list,
.criterio-list-prueba,
.criterio-list.competencia-criterio{
  padding-left: 0;
}

.criterio-list .criterio-list-pruebas-list .criterio-list-prueba{
  display:table;
  width: 100%;
  border-top: solid $list-group-border 1px;
  border-bottom: solid $list-group-border 1px;
  border-radius: 0;
  margin-bottom: -1px;
  & .codigo {
    display: table-cell;
    padding: 0;
    height: 100%;
    border-right: solid $list-group-border 1px;
    width: 90px;
    text-align:center;
    vertical-align:middle;
  }
  & .nombre{
    display: table-cell;
    padding: 5px;
    height: 100%;
    border-right: solid $list-group-border 1px;
    text-align:center;
    vertical-align:middle;
  }
  & .complejidad {
    display: table-cell;
    padding: 0;
    height: 100%;
    border-right: solid $list-group-border 1px;
    width: 130px;
    text-align:center;
    vertical-align:middle;
  }
  & .deseable {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 150px;
    border-right: solid $list-group-border 1px;
    text-align:center;
    vertical-align:middle;
  }
  & .prioridad {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 100px;
    text-align:center;
    vertical-align:middle;
  }
}