$colorBorderEstadoUsuario: $gray;
$borderEstadoUsuario: 2px;

.estadoUsuario {
  border-left: $borderEstadoUsuario $colorBorderEstadoUsuario solid;
  padding-left: 20px;
  margin-left: 25px;
  & .tabla {
    display: table;
    & .columna {
      display: table-row;
      & .celda {
        display: table-cell;
        padding-right: 10px;
      }
    }
  }
  & .lineaHorizontalEstado {
    padding: 0;
    margin: 0;
    margin-left: -20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-bottom: $borderEstadoUsuario $colorBorderEstadoUsuario solid;
  }
}

div.titulo-estado {
  width: calc(100% - 50px);
  background-color: $azul;
  margin-left: 25px;
  margin-right: 25px;
  padding: 5px 0 5px 20px;
  margin-bottom: 15px;
  & > p {
    color: white;
  }
}

.cuerpo-estado {
  margin-left: 50px;
  margin-right: 50px;
}

.competencias-estado .criterio-list-prueba {
  display: table;
  width: 100%;
  border-top: solid $list-group-border 1px;
  border-bottom: solid $list-group-border 1px;
  border-radius: 0;
  margin-bottom: -1px;
  page-break-inside: avoid;
  & .codigo {
    display: table-cell;
    padding: 0;
    height: 100%;
    border-right: solid $list-group-border 1px;
    border-left: solid $list-group-border 1px;
    width: 90px;
    text-align: center;
    vertical-align: middle;
    page-break-inside: avoid;
  }
  & .nombre {
    display: table-cell;
    padding: 5px;
    height: 100%;
    border-right: solid $list-group-border 1px;
    text-align: center;
    vertical-align: middle;
    page-break-inside: avoid;
  }
  & .complejidad {
    display: table-cell;
    padding: 0;
    height: 100%;
    border-right: solid $list-group-border 1px;
    width: 130px;
    text-align: center;
    vertical-align: middle;
    page-break-inside: avoid;
  }
  & .deseable {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 150px;
    border-right: solid $list-group-border 1px;
    text-align: center;
    vertical-align: middle;
    page-break-inside: avoid;
  }
  & .prioridad {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    border-right: solid $list-group-border 1px;
    page-break-inside: avoid;
  }
}

.competencias-estado .cuerpo-estado-partes {
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 6px;
}

.competencias-estado .criterio-list-name {
  display: table;
  width: 100%;
  border-top: solid $list-group-border 1px;
  border-bottom: solid $list-group-border 1px;
  margin-bottom: -1px;
  page-break-inside: avoid;
  & .nombre {
    border-left: solid $list-group-border 1px;
    display: table-cell;
    padding: 5px;
    height: 100%;
    border-right: solid $list-group-border 1px;
    text-align: center;
    vertical-align: middle;
    page-break-inside: avoid;
  }
  & .complejidad {
    display: table-cell;
    padding: 0;
    height: 100%;
    border-right: solid $list-group-border 1px;
    width: 130px;
    text-align: center;
    vertical-align: middle;
    page-break-inside: avoid;
  }
  & .deseable {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 150px;
    border-right: solid $list-group-border 1px;
    text-align: center;
    vertical-align: middle;
    page-break-inside: avoid;
  }
  & .prioridad {
    display: table-cell;
    padding: 0;
    height: 100%;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    border-right: solid $list-group-border 1px;
    page-break-inside: avoid;
  }
}

.lista-competencias-columa {
  display: table;
  width: 100%;
  page-break-inside: avoid;
  & .lista-competencias-celda {
    display: table-cell;
    page-break-inside: avoid;
    & .lista-competencias-celda-interna {
      border: solid $list-group-border 1px;
      border-radius: 0;
      padding: 5px;
      margin-bottom: -1px;
      padding-left: 4.2em;
      text-indent: -2.63em;
      page-break-inside: avoid;
    }
  }
}

@media print {
  .competencias-estado {
  }

  .competencias-estado .cuerpo-estado-partes {
    font-size: 12px;
  }

  .competencias-estado .criterio-list-prueba {
    font-size: 12px;

    & .codigo {
      width: 60px;
    }

    & .nombre {
    }

    & .complejidad {
      width: 90px;
    }

    & .deseable {
      width: 120px;
    }

    & .prioridad {
      width: 100px;
    }
  }

  .competencias-estado .criterio-list-name {
    font-size: 12px;

    & .nombre {
    }

    & .complejidad {
      width: 90px;
    }

    & .deseable {
      width: 120px;
    }

    & .prioridad {
      width: 100px;
    }
  }
}